<template>
    <ul class="items justify-content-between">
        <li class="item d-flex flex-column align-items-center" v-for="contact in contacts" :key="contact.title">
            <div class="icon d-flex align-items-center justify-content-center">
                <div class="img img-wrapper d-flex align-items-center justify-content-center">
                    <img :src="require(`./assets/${contact.icon}.svg`)" :alt="contact.title">
                </div>
            </div>
            <span>{{ contact.title }}</span>
            <a class="link-hover" v-if="contact.icon === 'phone'" :href="`tel:${contact.link}`">{{ contact.value }}</a>
            <a class="link-hover" v-else-if="contact.icon === 'email'" :href="`mailto:${contact.value}`">{{ contact.value }}</a>
            <span v-else>{{ contact.value }}</span>
        </li>
    </ul>
</template>

<script>
import contactsMixin from '@/mixins/contacts';

export default {
    mixins: [contactsMixin],
    data() {
        return {
            contacts: [
                {
                    title: 'Телефон',
                    value: '',
                    icon: 'phone',
                    link: '',
                },
                {
                    title: 'Эл. почта',
                    value: '',
                    icon: 'email',
                },
                {
                    title: 'Адрес',
                    value: '603022, Нижегородская область, г. Нижний Новгород, ул. Окский Съезд, д. 8, помещение/офис 3/301',
                    icon: 'address',
                },
            ],
        };
    },
    created() {
        this.contacts[0].value = this.phone;
        this.contacts[0].link = this.phoneLink;
        this.contacts[1].value = this.email;
    }
}
</script>

<style lang="scss" scoped>
.items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 31.8%);
}
.item {
  width: 100%;
  min-height: 220px;
  a, span {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #232323;
    display: inline-block;
    max-width: 294px;
  }
  span + span, span + a {
    padding-top: 4px;
  }
}
.icon {
  background: $sub-background;
  margin-bottom: 38px;
  width: 112px;
  height: 112px;
  .img {
    max-width: 60px;
    height: 60px;
    img {
      height: auto;
      max-width: 100%;
    }
  }
}
@media(max-width: $tablet) {
  .item {
    a, span {
      font-size: 21px;
      line-height: 26px;
    }
  }
}
</style>